@import "../base/variables.scss";
@import "../utils/functions.scss";

.base-layout {
  max-width: $root-layout-max-width;
  margin: 0 auto;
  padding: 0 $horizontal-padding-1;

  @media (max-width: $breakpoint-sm-3) {
    padding: 0 $horizontal-padding-2;
  }

  @media (max-width: 350px) {
    padding: 0 $horizontal-padding-3;
  }

  @media (min-width: $breakpoint-xl-1) {
    max-width: $root-layout-max-width-2;
  }
}

.base-btn {
  background-color: $color-primary;
  color: $color-white;
  font-weight: 500;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  text-align: center;
  border: none;
  border-radius: 0;
  font-size: 1.3rem;
  padding: 1.6rem 5rem;
  cursor: pointer;
  transition: all 0.2s;
}

.universal-section-top-margin {
  margin-top: $universal-section-gap !important;

  @media (max-width: $breakpoint-md-1) {
    margin-top: calc($universal-section-gap / 1.3) !important;
  }
}

.universal-section-top-padding {
  padding-top: $universal-section-gap !important;

  @media (max-width: $breakpoint-md-1) {
    padding-top: calc($universal-section-gap / 1.3) !important;
  }
}

.features-section-top-gap {
  padding-top: $features-section-gap !important;

  @media (max-width: $breakpoint-md-1) {
    padding-top: $features-section-gap * 2.6 !important;
  }
}

.highlighted-text {
  color: $color-black;
  background-color: $color-text-bg;
  padding: 0 0.3rem;
}

.block-after-lg {
  display: none;

  @media (min-width: $breakpoint-lg-1) {
    display: block;
  }
}

.section-heading {
  font-size: fluid-size(2, 3);
  font-weight: bold;
}

.section-subheading {
  text-align: center;
  font-size: fluid-size(1.4, 1.5);
  letter-spacing: 0px;
  color: #656e7e;
  opacity: 0.94;
  line-height: 1.4;
}

.features-heading {
  font-size: fluid-size(2, 3);
}
