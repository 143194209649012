@import "./shared.scss";

.feature-3 {
  @extend .base-layout;
  @extend .features-section-top-gap;

  // @media (max-width: $breakpoint-lg-1) {
  //   padding-top: 11rem;
  // }

  &__body {
    @extend .feature-body;
  }

  &__picture {
    width: 80%;

    @media (min-width: $breakpoint-md-2) {
      grid-column: col-start 1 / col-end 3;
      grid-row: 1 / -1;
      align-content: center;
      width: 100%;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    padding: 0;

    @media (min-width: $breakpoint-md-2) {
      grid-column: col-start 4 / col-end 8;
      grid-row: 1 / -1;
      padding: 6rem 0 6rem 8vw;
    }
  }
}
