@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.insights-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: 24rem;

  @media (max-width: 500px) {
    max-width: 100%;
  }

  &__heading-container {
    padding: 3.9rem;
    background-color: $color-text-bg-2;
    border-radius: 50%;
    position: relative;

    @media (max-width: $breakpoint-sm-2) {
      padding: 3.3rem;
    }

    @media (min-width: $breakpoint-xl-1) {
      padding: 4.3rem 4rem;
    }
  }

  &__text {
    &--variant-1 {
      position: absolute;

      top: 25%;
      left: 10%;

      font-size: fluid-size(2.5, 3.5);
      font-weight: 600;

      @media (min-width: $breakpoint-xl-1) {
        font-size: 3.9rem;
      }
    }

    &--variant-2 {
      position: absolute;

      bottom: 0;
      left: 10%;

      font-size: fluid-size(1.3, 1.5);
      font-weight: bold;
      color: $color-primary;
      z-index: 2;
      text-wrap: nowrap;

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.7rem;
      }
    }

    &--variant-3 {
      margin-left: 0.8rem;
      // font-size: fluid-size(1.3, 1.3);
      font-size: 1.3rem;
      font-weight: normal;
      line-height: 1.4;

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.5rem;
      }
    }
  }
}
