@import "./shared.scss";
@import "../utils/functions.scss";
@import "../base/typography.scss";

.feature-1 {
  @extend .base-layout;
  @extend .universal-section-top-padding;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: $features-section-gap;

  &__heading {
    @extend .features-heading;
    font-weight: bold;
    margin: 0 auto;

    @media (max-width: $breakpoint-md-1) {
      text-align: center;
      width: 90%;
      line-height: 1.7;
    }

    &--highlighted {
      @extend .highlighted-text;
    }
  }

  &__body {
    @extend .feature-body;
  }

  &__picture {
    width: 80%;

    @media (min-width: $breakpoint-md-2) {
      grid-column: col-start 1 / col-end 3;
      grid-row: 1 / -1;
      align-content: center;
      width: 100%;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    padding: 0;

    @media (min-width: $breakpoint-md-2) {
      grid-column: col-start 4 / col-end 8;
      grid-row: 1 / -1;
      padding: 6rem 0 6rem 8vw;
    }
  }

  &__content-details {
    @extend .feature__content-details;
  }
}
