@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.dynamic-page-header {
  @extend .base-layout;
  margin-top: 4rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem;

  @media (min-width: $breakpoint-md-1) {
    display: grid;
    grid-template-columns: 40% 60%;
    column-gap: 0;
    row-gap: 6rem;
  }

  @media (min-width: $breakpoint-xl-1) {
    row-gap: 8rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    @media (min-width: $breakpoint-md-1) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 3rem;
    }

    @media (min-width: $breakpoint-lg-1) {
      margin-top: 2rem;
    }

    @media (min-width: $breakpoint-xl-1) {
      margin-top: 6rem;
    }
  }

  &__text {
    &--variant-1 {
      font-size: fluid-size(2.1, 3.1);
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.5;

      @media (max-width: $breakpoint-md-1) {
        text-align: center;
      }

      &--highlighted {
        @extend .highlighted-text;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 3.3rem;
      }
    }

    &--variant-2 {
      font-size: fluid-size(1.2, 1.5);
      font-weight: 400;
      letter-spacing: 0px;
      opacity: 0.94;
      line-height: 1.6;

      width: 80%;

      @media (max-width: $breakpoint-md-1) {
        text-align: center;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.7rem;
      }
    }

    &--variant-3 {
      font-size: fluid-size(1.2, 1.4);
      font-weight: 600;
      letter-spacing: 0px;
      opacity: 0.94;
      line-height: 1.5;

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.6rem;
      }

      @media (min-width: $breakpoint-md-1) {
        margin-top: -1rem;
      }
    }
  }

  &__btn {
    @extend .base-btn;

    font-size: 1.4rem;
    font-family: $font-Montserrat;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 1.5rem 3rem;
    border-radius: 0;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 1.7rem;
    }
  }

  &__image {
    justify-self: self-end;
    padding: 1rem;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (min-width: $breakpoint-md-1) {
      width: 95%;
    }
  }

  &__insights {
    grid-column: 1 / -1;
    display: grid;
    gap: 3.5rem;
    // width: 100%;

    @media (min-width: $breakpoint-sm-3) {
      grid-template-columns: 1fr 1fr;
      width: auto;
    }

    @media (min-width: $breakpoint-lg-1) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @media (max-width: $breakpoint-sm-3) {
      width: 100%;
      gap: 2.2rem;
    }
  }
}
