@import "../base/variables.scss";
@import "../utils/functions.scss";
@import "../base/typography.scss";

.investors-features-card {
  background: #e6eeff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 18px #00000003;
  border: 2px solid #97b6ff;

  border-radius: 17px;

  width: 100%;
  padding: 2.7rem 2.4rem;
  padding-top: 1.7rem;

  @media (max-width: $breakpoint-sm-3) {
    padding: 2.7rem 2.2rem;
  }

  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;

  &__text-1 {
    font-size: fluid-size(1.5, 1.6);
    font-weight: 600;
    text-align: center;
    margin-top: 1.2rem;
    line-height: 1.4;

    @media (max-width: $breakpoint-sm-2) {
      text-align: left;
    }

    @media (min-width: $breakpoint-xl-1) {
      font-size: 2rem;
    }
  }

  &__text-2 {
    font-size: fluid-size(1.2, 1.2);
    font-weight: normal;
    line-height: 1.5;
    opacity: 0.94;
    text-align: center;

    @media (max-width: $breakpoint-sm-2) {
      text-align: left;
    }

    @media (min-width: $breakpoint-xl-1) {
      font-size: 1.4rem;
    }
  }
}
