@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.insights-3-wrapper {
  display: flex;

  width: 100%;

  @media (max-width: $breakpoint-sm-3) {
    padding: 2rem 3rem;
  }
}

.insights-3 {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: flex-start;
  row-gap: 1.2rem;
  column-gap: 1.7rem;

  &__img {
    height: auto;
    grid-row: 1 / span 2;
    width: 4.7rem;

    @media (min-width: $breakpoint-xl-1) {
      width: 5.5rem;
    }
  }

  &__text-1 {
    font-size: fluid-size(1.5, 1.8);
    font-weight: bold;
    line-height: 1.4;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 2rem;
    }
  }

  &__text-2 {
    font-weight: 400;
    line-height: 1.5;

    font-size: 1.2rem;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 1.5rem;
    }
  }
}
