@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.pricing-v4 {
  @extend .base-layout;
  @extend .universal-section-top-padding;

  display: grid;
  grid-template-columns: repeat(8, [col-start] minmax(min-content, $root-layout-max-width) [col-end]);

  @media (max-width: $breakpoint-md-1) {
    display: flex;
    flex-direction: column;
    gap: 9rem;
  }

  &__text-container {
    grid-column: col-start 1 / col-end 2;
    grid-row: 1 / -1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $breakpoint-lg-2) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      grid-column: col-start 1 / col-end 3;
    }

    @media (max-width: $breakpoint-md-2) {
      grid-column: col-start 1 / col-end 4;
    }
  }

  &__text {
    &--primary {
      @extend .section-heading;
      font-weight: normal;
      line-height: 1.7;

      @media (max-width: $breakpoint-lg-2) {
        line-height: 2.2;
      }
    }

    &--secondary {
      font-size: fluid-size(1.3, 1.5);
      font-weight: normal;
      opacity: 0.76;
      margin-top: 2rem;
      line-height: 1.8;

      @media (max-width: $breakpoint-lg-2) {
        width: 90%;
        text-align: center;
      }
    }

    &--highlighted {
      @extend .section-heading;
      font-weight: normal;
      line-height: 1.4;
      font-weight: bold;

      @extend .highlighted-text;
    }
  }

  &__action-btn {
    @extend .base-btn;
    padding: 1.6rem 7rem;
    font-weight: bold;
    letter-spacing: 0.36px;
    font-size: 1.6rem;
    border-radius: 4px;
    margin-top: 2.4rem;
  }

  &__cards {
    grid-column: col-start 4 / col-end 8;
    grid-row: 1 / -1;
    justify-self: flex-end;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    @media (max-width: $breakpoint-md-1) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
    }
  }

  &__card {
    position: relative;
    padding: 0 2rem;
    padding-top: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #a7bef3;
    border-radius: 8px;
    width: 35rem;
    height: 24rem;
    background-color: $color-white;
    margin-bottom: 2.2rem;

    @media (min-width: $breakpoint-md-1) and (max-width: 820px) {
      width: 32rem;
    }

    @media (max-width: 420px) {
      width: 100%;
    }

    @media (min-width: $breakpoint-xl-1) {
      width: 39rem;
    }
  }

  &__card-content {
    width: 100%;
  }

  &__card-plan-selector-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &__content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border: 1px solid #bbcef8;
      border-radius: 18px;
    }
  }

  &__card-plan-selector-btn {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: -0.39px;
    color: $color-black;
    background-color: $color-white;
    border-radius: 18px;
    cursor: pointer;

    &--annual {
      padding-left: 0.7rem;
      padding-right: 0.5rem;
    }

    &--monthly {
      padding-left: 0.5rem;
      padding-right: 0.7rem;
    }

    &--selected {
      color: $color-white;
      background-color: $color-primary;
      border: 1px solid $color-primary;
      padding: 0.5rem 1rem;
    }
  }

  &__card-name {
    margin-top: 4rem;
    font-weight: bold;
    font-size: 2.5rem;
    text-align: center;
    color: $color-black;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 2.2rem;
    }
  }

  &__card-description {
    margin-top: 1.6rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: $color-black;
    line-height: 1.6;
    text-align: center;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 1.5rem;
    }
  }

  &__card-promo-container {
    min-height: 3rem;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16.5rem;
    margin-top: 0.8rem;
  }

  &__card-promo-image {
    width: 100%;
    height: 100%;
  }

  &__card-promo-text {
    position: absolute;
    bottom: 14%;
    left: 5%;

    font-size: 1.25rem;
    font-weight: 600;
    text-align: center;
  }

  &__card-price-container {
    position: absolute;
    bottom: 8.25rem;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  &__card-price {
    font-weight: bold;
    font-size: 2rem;
    color: $color-black;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 2.7rem;
    }

    &--primary {
      text-align: center;
      font-weight: 500;
      font-size: 1.8rem;
      color: $color-black;
    }

    &--secondary {
      min-height: 1.1rem;
      max-height: 1.1rem;
      text-align: center;
      font-weight: normal;
      font-size: 1.1rem;
      color: $color-black;
      text-transform: capitalize;
      margin-top: 1rem;
      opacity: 0.62;
    }
  }

  &__card-btn {
    @extend .base-btn;

    position: absolute;
    background-color: $color-primary;
    color: $color-white;
    font-weight: bold;
    letter-spacing: 0.36px;
    font-size: 1.65rem;
    padding: 1.6rem 8rem;
    bottom: 3rem;
    border: 1px solid #0c3aa4;
    border-radius: 4px;

    cursor: pointer;

    @media (max-width: 350px) {
      font-size: 1.3rem;
    }
  }
}
