@import "./base/base.scss";

button:focus {
  outline: 0px !important;
}

/* prevent-drag */
img {
  user-drag: none;
  -webkit-user-drag: none; /* For older versions of WebKit browsers */
  -webkit-touch-callout: none; /* Disable callout on long-press (iOS) */
}

a {
  text-decoration: none;
}
