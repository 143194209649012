@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.insights-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--variant-1 {
    width: 100%;

    @media (max-width: $breakpoint-lg-1) {
      background-color: $color-white;
      border: 1px solid $color-border;
      border-radius: 14px;
      padding: 2rem 2rem;
    }
  }
}

.insights {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  row-gap: 0.2rem;
  column-gap: 1rem;

  @media (min-width: $breakpoint-sm-3) and (max-width: $breakpoint-lg-1) {
    margin-left: 1rem;
  }

  &__img {
    height: auto;
    grid-row: 1 / span 2;
    width: 4rem;

    &--variant-1 {
      width: 5rem;

      @media (min-width: $breakpoint-xl-1) {
        width: 7rem;
      }
    }

    &--variant-2 {
      width: 5rem;

      @media (min-width: $breakpoint-xl-1) {
        width: 6rem;
      }
    }
  }

  &__text-1 {
    font-size: fluid-size(1.5, 1.8, 320, 640);
    font-weight: bold;
    line-height: 1;

    &--variant-1 {
      @media (min-width: $breakpoint-sm-3) {
        font-size: fluid-size(1.5, 2, 640, 1024);
      }

      @media (min-width: $breakpoint-lg-1) {
        font-size: fluid-size(1.4, 1.7, 1024, 1920);
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 2rem;
      }
    }
  }

  &__text-2 {
    font-weight: 400;
    line-height: 1.3;

    &--variant-1 {
      font-size: fluid-size(1.2, 1.8, 320, 640);

      @media (min-width: $breakpoint-sm-3) {
        font-size: fluid-size(1.2, 1.5, 640, 1024);
      }

      @media (min-width: $breakpoint-lg-1) {
        font-size: fluid-size(1, 1.3, 1024, 1920);
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.5rem;
      }
    }

    &--variant-2 {
      font-size: fluid-size(1.1, 1.2);

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.5rem;
      }
    }
  }
}
