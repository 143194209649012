@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.app-download-section {
  @extend .universal-section-top-margin;

  max-width: 100%;
  background-color: $color-primary;
  padding: 4rem 0;

  @media (min-width: $breakpoint-md-1) {
    padding: fluid-size-without-clamp(4, -4, 768, 1920) 0;
  }
}

.app-download {
  @extend .base-layout;

  display: grid;
  grid-template-columns: repeat(8, [col-start] minmax(2rem, $root-layout-max-width) [col-end]);
  align-items: center;
  @media (max-width: $breakpoint-md-1) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__content {
    grid-column: col-start 1 / col-end 3;
    grid-row: 1 / -1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media (max-width: $breakpoint-md-1) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__text-1 {
      @extend .section-heading;
      font-weight: normal;
      color: $color-white;
      line-height: 1.4;

      &--with-bg {
        font-weight: bold;
        color: $color-black;
        background-color: $color-text-bg;
        padding: 0 0.3rem;
      }
    }

    &__text-2 {
      font-size: fluid-size(1.25, 1.4);
      line-height: 1.6;
      font-weight: normal;
      color: $color-white;
      opacity: 0.84;
      margin-top: 1.1rem;
      margin-bottom: 3.9rem;

      @media (max-width: $breakpoint-md-1) {
        width: 80%;
        text-align: center;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.5rem;
      }
    }
  }

  &__image {
    display: block;
    grid-column: col-start 6 / col-end 8;
    grid-row: 1 / -1;

    @media (max-width: $breakpoint-md-1) {
      display: none;
    }

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.download-app-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  &__image {
    height: 100%;
    object-fit: contain;

    @media (min-width: $breakpoint-sm-1) {
      width: 14rem;
    }

    @media (min-width: $breakpoint-lg-1) {
      width: 15rem;
    }

    @media (min-width: $breakpoint-xl-1) {
      width: 16rem;
    }
  }
}
