@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.investors-features-2 {
  @extend .base-layout;
  padding-top: 19rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7rem;

  @media (max-width: $breakpoint-md-1) {
    margin-top: 1rem;
    padding-top: 8rem;
  }

  @media (min-width: $breakpoint-xl-1) {
    display: grid;
    grid-template-columns: repeat(8, [col-start] minmax(min-content, $root-layout-max-width) [col-end]);
    gap: 8rem;
  }

  @media (max-width: $breakpoint-lg-2) {
    gap: 5rem;
  }

  &__heading-container {
    grid-column: col-start 1 / col-end 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2.5rem;

    @media (min-width: $breakpoint-xl-1) {
      grid-column: col-start 1 / col-end 2;
    }

    @media (max-width: $breakpoint-xl-1) {
      align-items: center;
      text-align: center;
      gap: 1.9rem;
    }

    &__text-1 {
      @extend .section-heading;
      font-weight: bold;
    }

    &__text-2 {
      font-weight: normal;
      font-size: fluid-size(1.3, 1.4);
      opacity: 0.76;
      line-height: 1.6;

      @media (max-width: $breakpoint-md-1) {
        width: 90%;
        text-align: center;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.55rem;
      }
    }
  }

  &__steps {
    grid-column: col-start 4 / col-end 8;

    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    gap: 2rem;

    @media (max-width: $breakpoint-md-1) {
      display: grid;
    }

    @media (min-width: $breakpoint-xl-1) {
      grid-column: col-start 3 / col-end 8;
    }
  }
}
