@import "./shared.scss";
@import "../utils/functions.scss";
@import "../base/typography.scss";

.investors-features-1 {
  @extend .base-layout;

  // @extend .universal-section-top-padding;
  padding-top: 9rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 4rem;

    @media (max-width: $breakpoint-md-1) {
      width: 100%;
      flex-direction: column;
      gap: 2rem;
    }
  }
}
