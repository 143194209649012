@import "../utils/functions.scss";
@import "../base/typography.scss";

.investors-mission {
  @extend .base-layout;
  padding-top: 15rem;

  @media (max-width: $breakpoint-sm-3) {
    padding-top: 10rem;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    margin-top: 3rem;

    @media (max-width: $breakpoint-lg-1) {
      margin-top: 4rem;
    }
  }
}
