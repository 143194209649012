@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.feature__content {
  display: grid;
  align-content: center;
  justify-content: start;
  gap: 2.2rem;

  &__text {
    @extend .features-heading;
    line-height: 1.5;
    letter-spacing: 0px;
    text-transform: capitalize;

    &--1 {
      font-weight: 400;
    }

    &--2 {
      font-weight: 700;
      color: #244a8a;
    }

    &--3 {
      font-weight: 400;
    }
  }

  &__text-2 {
    font-weight: 400;
    font-size: fluid-size(1.4, 1.6);
    line-height: 1.5;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    text-align: left;

    &--bold {
      font-weight: bold;
    }
  }

  &__action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.8rem;
    gap: 1.2rem;
    // text-wrap: nowrap;

    &__btn {
      @extend .base-btn;
      padding: 1.8rem 4rem;

      @media (max-width: $breakpoint-lg-1) {
        padding: 1.4rem 2.5rem;
      }

      @media (max-width: $breakpoint-md-2) {
        padding: 1.4rem 2.4rem;
        font-size: 1.1rem;
      }

      @media (max-width: $breakpoint-sm-2) {
        width: 35%;
      }

      @media (max-width: 400px) {
        padding: 1.4rem 1.2rem;
      }

      @media (min-width: $breakpoint-md-1) {
        text-wrap: nowrap;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.5rem;
      }
    }
  }
}

.feature-body {
  display: grid;
  grid-template-rows: clamp(200px, 40vw, 450px);
  // grid-template-columns: repeat(8, [col-start] minmax(2rem, 12rem) [col-end]);
  // width: 100%;
  // justify-content: center;
  grid-template-columns: repeat(8, [col-start] minmax(2rem, $root-layout-max-width) [col-end]);

  @media (max-width: $breakpoint-md-2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10rem;
  }

  @media (max-width: $breakpoint-lg-1) {
    gap: 7rem;
  }
}

.feature__content-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.5rem;
}

.section-heading {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.7rem;
  }

  &__text-1 {
    @extend .section-heading;
    text-align: center;
    line-height: 1.4;
  }

  &__text-2 {
    @extend .section-subheading;
    width: 70%;

    @media (max-width: $breakpoint-md-1) {
      width: 90%;
    }
  }
}
