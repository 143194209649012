@import "../base/variables.scss";
@import "../utils/functions.scss";

.registration-step-card {
  background-color: $color-white;
  border: 1px solid $color-border;
  border-radius: 14px;
  opacity: 1;

  width: 100%;
  height: 14rem;
  padding: 2.5rem;
  padding-top: 1.7rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 550px) {
    width: 100%;
    height: 11rem;
  }

  @media (min-width: $breakpoint-md-1) {
    height: 18.8rem;
  }

  &__icon {
    width: 2rem;
    display: none;

    @media (min-width: $breakpoint-md-1) {
      display: block;
    }
  }

  &__text-1 {
    font-size: fluid-size(1.7, 1.95);
    font-weight: bold;
    text-align: left;
    margin-top: 1.2rem;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 2rem;
    }
  }

  &__text-2 {
    font-size: fluid-size(1.3, 1.3);
    font-weight: normal;
    color: #707070;
    text-align: left;
    letter-spacing: 0px;
    margin-top: 2rem;
    line-height: 1.5;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 1.4rem;
    }
  }
}
