// COLORS
$color-primary: #0c3aa4;
$color-secondary: #244a8a;
$color-text-bg: #c1f219;
$color-text-bg-2: #e7f5c8;
$color-text-bg-3: #e2ff80;
$color-black: #000000;
$color-white: #ffffff;
$color-grey-variant-1: #707070;
$color-grey-variant-2: #ffffffbd;
$color-border: #cfd8e8;
$color-border-2: #c1f219;

// FONT VARIABLES
$font-Montserrat: "Montserrat", sans-serif;
$font-DM-Sans: "DM Sans", sans-serif;

// Layout
$navbar-height: 9rem;
$pricing-card-horizontal-padding: 3rem;

// Breakpoints
$adjustment: 0.02px;

$breakpoint-sm-1: 20em; // 320px;
$breakpoint-sm-2: calc(30em + $adjustment); // 480px;
$breakpoint-sm-3: calc(40em + $adjustment); // 640px
$breakpoint-md-1: calc(48em + $adjustment); // 768px
$breakpoint-md-2: calc(52.5em + $adjustment); // 840px
$breakpoint-lg-1: calc(64em + $adjustment); //1024px
$breakpoint-lg-2: calc(73.12em + $adjustment); // 1170px
$breakpoint-xl-1: calc(110em + $adjustment); // 1760px
$breakpoint-xl-2: calc(120em + $adjustment); // 1920px

// Screen Size
$min-screen-width: 320;
$max-screen-width: 1920;

// Root Layout Padding
$root-layout-max-width: 114rem; // 1140px
$root-layout-max-width-2: 140rem; // 1400px
$horizontal-padding-1: 6rem;
$horizontal-padding-2: 2.4rem;
$horizontal-padding-3: 1.5rem;

// GAPS
$universal-section-gap: 12rem;
$features-section-gap: 4rem;
