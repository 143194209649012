@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.showcase-section {
  @extend .base-layout;
  @extend .universal-section-top-padding;
}

.showcase {
  max-width: 100rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8rem;

  &__heading {
    @extend .section-heading;
    font-family: $font-Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 1.6;

    &--highlighted {
      @extend .highlighted-text;
    }
  }

  &__cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    column-gap: 2rem;
    row-gap: 3.5rem;
    justify-items: center;
  }

  &__card {
    max-width: 27rem;
    height: 26rem;

    background-color: $color-white;
    border-radius: 14px;
    padding: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;

    @media (max-width: $breakpoint-sm-2) {
      max-width: 100%;
      height: 100%;
    }
  }

  &__card-image-container {
    width: 100%;
    height: 85%;
  }

  &__card-image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &--blend {
      mix-blend-mode: luminosity;
      cursor: default;
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__card-text {
    font-family: $font-Montserrat;
    font-weight: 600;
    font-size: fluid-size(1.4, 2);
  }
}
