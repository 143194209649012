@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.investors-page-header {
  @extend .base-layout;
  margin-top: 5.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: $breakpoint-xl-1) {
    margin-top: 10rem;
  }

  &__text {
    &--variant-1 {
      font-size: fluid-size(1.1, 1.15);
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.7;
      color: $color-black;
      text-align: center;
      background: $color-text-bg-3 0% 0% no-repeat padding-box;
      border: 1px solid $color-border-2;
      border-radius: 22px;
      padding: 0.55rem 5rem;

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.5rem;
      }
    }

    &--variant-2 {
      font-size: fluid-size(2, 2.9);
      font-weight: bold;
      line-height: 1.8;
      text-align: center;

      @media (min-width: $breakpoint-xl-1) {
        font-size: 3.5rem;
      }
    }

    &--variant-3 {
      font-size: fluid-size(1.3, 1.3);
      font-weight: 400;
      text-align: center;
      line-height: 1.6;
      opacity: 0.94;
      text-align: center;
      color: #656e7e;

      width: 90%;

      @media (min-width: $breakpoint-sm-3) {
        width: 70%;
      }

      @media (min-width: $breakpoint-md-1) {
        width: 50%;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.6rem;
      }
    }

    &-2 {
      margin-top: 3.2rem;
    }

    &-4 {
      margin-top: 2.5rem;
    }
  }

  &__image {
    width: 80%;
    height: 100%;
    object-fit: contain;

    @media (max-width: $breakpoint-lg-1) {
      margin-top: 4rem;
    }
  }
}
