@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.landing-page-header {
  @extend .base-layout;
  margin-top: 5.8rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12rem;

  @media (min-width: $breakpoint-md-1) {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 0;
    row-gap: 6rem;
  }

  @media (min-width: $breakpoint-lg-1) {
    grid-template-columns: 56% 44%;
  }

  @media (min-width: $breakpoint-xl-1) {
    margin-top: 10rem;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.2rem;

    @media (min-width: $breakpoint-md-1) {
      align-items: flex-start;
    }

    @media (max-width: $breakpoint-sm-2) {
      gap: 3.7rem;
    }
  }

  &__text {
    &--variant-1 {
      font-size: fluid-size(1.6, 1.7);
      font-weight: bold;
      letter-spacing: 0px;
      line-height: 1.7;
      color: $color-primary;
      text-align: center;

      &--highlighted {
        @extend .highlighted-text;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 2.4rem;
      }
    }

    &--variant-2 {
      font-size: fluid-size(2.3, 2.5);
      font-weight: bold;
      line-height: 1.6;
      text-align: center;

      &--highlighted {
        @extend .highlighted-text;
      }

      @media (min-width: $breakpoint-md-1) {
        text-align: left;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 3.5rem;
      }
    }

    &--variant-3 {
      font-size: fluid-size(1.3, 1.3);
      font-weight: 500;
      text-align: center;
      line-height: 1.6;
      opacity: 0.94;
      text-align: center;

      @media (min-width: $breakpoint-md-1) {
        text-align: left;
      }

      @media (min-width: $breakpoint-sm-2) {
        width: 80%;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.9rem;
        opacity: 0.8;
      }
    }
  }

  &__btn {
    @extend .base-btn;

    font-size: 1.4rem;
    font-family: $font-Montserrat;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: 1.5rem 3rem;
    border-radius: 0;

    @media (min-width: $breakpoint-xl-1) {
      font-size: 1.7rem;
      padding: 2rem 4.3rem;
    }
  }

  &__image {
    justify-self: self-end;
    padding: 1rem;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (min-width: $breakpoint-md-1) {
      // width: 95%;
    }
  }

  &__insights {
    grid-column: 1 / -1;

    width: 100%;
    gap: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));

    justify-items: center;
  }
}
