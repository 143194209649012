@import "../base/variables.scss";
@import "../utils/functions.scss";

.investors-features-card-2 {
  background-color: $color-white;
  border: 1px solid $color-border;
  border-radius: 14px;
  opacity: 1;

  width: 100%;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  &__icon {
    width: 2rem;
    display: none;

    @media (min-width: $breakpoint-md-1) {
      display: block;
    }

    margin-bottom: 1.2rem;
  }

  &__text-1 {
    font-size: fluid-size(1.7, 1.95);
    font-weight: bold;
    text-align: left;
    line-height: 1.4;
  }

  &__text-2 {
    font-size: fluid-size(1.3, 1.3);
    font-weight: normal;
    color: #707070;
    text-align: left;
    letter-spacing: 0px;
    margin-top: 2rem;
    line-height: 1.5;
  }
}
