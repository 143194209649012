@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.sticky-navbar {
  max-width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background-color: $color-white;
  // background-color: rgba(255, 255, 255, 0.25);
  // backdrop-filter: blur(10px);
  // -webkit-backdrop-filter: blur(10px);
  z-index: 999;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.06);
  transition: all 0.3s ease-in-out;

  & > .navbar {
    height: 7rem;
  }

  & .navbar-logo {
    width: 13rem;
  }
}

.navbar-logo {
  width: 15rem;
  height: 15rem;
  cursor: pointer;

  @media (min-width: $breakpoint-xl-1) {
    width: 17rem;
    height: 17rem;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.navbar-section {
  max-width: 100%;
}

.navbar {
  @extend .base-layout;

  height: 9rem;

  display: flex;
  align-items: center;

  &--center {
    justify-content: center;
  }

  &--between {
    justify-content: space-between;
  }

  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;

    &__item {
      list-style: none;
      cursor: pointer;

      &--btn {
        margin-left: 1.9rem;
      }
    }

    &__link {
      font-size: 1.3rem;
      font-weight: 600;
      letter-spacing: 0.16px;
      color: $color-black;

      &--1 {
        color: $color-primary;
      }

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.5rem;
      }

      &--dropdown {
        background: transparent !important;
        border: none;
        border-color: transparent !important;
        padding: 0 !important;

        font-size: 1.3rem;
        font-weight: 600;
        letter-spacing: 0.16px;
        color: $color-black;
        line-height: 1;
      }
    }

    &__btn {
      @extend .base-btn;
      padding: 1.3rem 2rem;
      font-weight: 600;
      font-family: $font-Montserrat;

      @media (min-width: $breakpoint-xl-1) {
        font-size: 1.5rem;
      }
    }
  }

  &__menu-btn {
    width: 3.3rem;
  }
}

.mobile-nav {
  background-color: $color-white;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999;

  & > * {
    overflow-y: auto;
  }

  &__logo {
    width: 15rem;
  }
  &__close-icon {
    width: 1.7rem;
  }

  &__list-item {
    display: inline-block;
    list-style: none;
    font-size: fluid-size(1.5, 1.8, 320, 768);
    border-bottom: 1px solid #e7e7e7;
    width: 100%;
    padding-bottom: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    color: $color-black;

    &--no-border {
      border-bottom: 0 !important;
    }

    &--2 {
      font-size: fluid-size(1.2, 1.5, 320, 768);
      margin-left: 2rem;
      padding-bottom: 1rem;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    padding: 3rem 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }

  &__top-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 6rem;

    &__row-1 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__row-2 {
      width: 100%;

      & > ul {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3rem;
      }

      & > ul > li {
        list-style: none;
        width: 100%;

        & > a {
          &:visited {
            color: $color-black;
          }
        }
      }
    }
  }

  &__bottom-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;

    &__row-1 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 2rem;
    }
    &__row-2 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.forward-animation {
  left: 100%;
  animation: from-right;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}

.reverse-animation {
  left: 0%;
  animation: to-right;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}

@keyframes from-right {
  from {
    left: 100%;
    top: 0px;
  }
  to {
    left: 0px;
    top: 0px;
  }
}

@keyframes to-right {
  from {
    left: 0%;
    top: 0px;
  }
  to {
    left: 100%;
    top: 0px;
  }
}

.sidebar-collapse {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid #e7e7e7;
}

.sidebar-collapse-list {
  margin-bottom: 1.8rem;

  &__menu {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
  }
  &__item {
    list-style: none;
    cursor: pointer;
    width: 100%;

    &--btn {
      margin-left: 1.9rem;
    }
  }
}
