@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.insights-section {
  @extend .base-layout;
  @extend .universal-section-top-padding;

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4rem;

  @media (min-width: $breakpoint-lg-1) {
    display: grid;
    grid-template-columns: 40% 40%;
    column-gap: 0;
    row-gap: 6rem;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    justify-items: center;
  }

  &__widgets {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // gap: 2rem;

    // @media (min-width: $breakpoint-md-1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 5rem;
    // }
  }

  &__image {
    justify-self: self-end;
    padding: 1rem;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media (min-width: $breakpoint-md-1) {
      width: 95%;
    }
  }
}
