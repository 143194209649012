@import "./variables.scss";
@import "../utils/functions.scss";

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px
}

body {
  font-family: $font-Montserrat;
  color: $color-black;
  line-height: 1;
}

.main-container {
  width: 100%;
  background-image: url("https://assets.boomingo.io/boomingo/website/images/app/bg.svg");
  background-size: cover;
}

.page-container {
  width: 100%;
}
