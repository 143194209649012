@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.footer {
  @extend .base-layout;

  padding-top: calc($universal-section-gap / 2);
  padding-bottom: calc($universal-section-gap / 2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5rem;

  &__row-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: $breakpoint-sm-3) {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
    }

    &__text-1 {
      font-size: fluid-size(2, 3);
      font-weight: bold;
      font-family: $font-DM-Sans;
    }

    &__social-links-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.7rem;
    }

    .social-link-icon {
      width: 2rem;
    }
  }

  &__copyright-text {
    font-size: fluid-size(1.2, 1.5);

    &--1 {
      font-weight: normal;
    }

    &--2 {
      font-weight: bold;
    }
  }
}
