@import "./shared.scss";
@import "../utils/functions.scss";
@import "../base/typography.scss";

.investors-insights {
  @extend .base-layout;
  @extend .universal-section-top-padding;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8rem;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
