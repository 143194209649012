@import "../base/variables.scss";
@import "../base/typography.scss";
@import "../utils/functions.scss";

.investors-footer {
  @extend .base-layout;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2.5rem;
  padding-top: 15rem;
  padding-bottom: 10rem;

  @media (max-width: $breakpoint-sm-3) {
    padding-top: 10rem;
    padding-bottom: 6rem;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 4rem;

    @media (max-width: $breakpoint-sm-3) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5.5rem;
    }
  }

  &__text-1 {
    font-size: fluid-size(2, 2.2);
    font-weight: bold;
    line-height: 1.4;

    @media (max-width: $breakpoint-sm-3) {
      text-align: center;
    }
  }

  &__text-2 {
    margin-top: 3.4rem;
    font-size: fluid-size(1.25, 1.5);
    font-weight: normal;
    color: #656e7e;
    line-height: 1.4;

    @media (max-width: $breakpoint-sm-3) {
      text-align: center;
    }
  }

  &__text-3 {
    margin-top: 1.5rem;
    font-size: fluid-size(1.25, 1.5);
    font-weight: bold;
    line-height: 1.4;

    @media (max-width: $breakpoint-sm-3) {
      text-align: center;
    }
  }

  &__btn {
    background: $color-primary 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    padding: 1.5rem 2.1rem;
    cursor: pointer;

    font: normal normal bold fluid-size(1.5, 1.6) / 1 Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    line-height: 1.4;
  }

  &__email {
    text-decoration: none;
    color: inherit;
    text-transform: lowercase;
  }
}
